<template>
  <div title="PrivacyPolicy" style="background-color: #eff3f5;height: 100%;width: 100%;overflow: scroll" align="center" overflow="scroll">
    <a-card style="background-color: white;width: 1100px;margin-top: 50px;padding-left: 20px;padding-right: 20px">
      <div style="margin-top: 20px">
        <h3>
          <p style="font-size: 26px">Privacy Policy for {{ themeData.saasMerNameEn }} Services</p>
        </h3>
      </div>
      <div align="left" style="margin-top: 50px">
        <div>
          <p>
            Your privacy is important to us. At {{ themeData.saasMerNameEn }} we have a few fundamental principles:<br>
            • We don’t ask you for personal information unless we truly need it.<br>
            • We don’t share your personal information with anyone except to comply with the law, provide our services, or protect our rights.<br>
            • We restrict access to your personal information to employees and service providers who need to know that information to provide services to you.<br>
            • We maintain physical, electronic and process security protocols to protect your personal information.<br>
            This Privacy Policy, together with our {{ themeData.saasMerNameEn }} Services Terms and Conditions, governs {{ themeData.saasMerNameEn }}’s collection, processing and use of your Personal Information. As used in this Privacy Policy, "{{ themeData.saasMerNameEn }}" refers to {{ themeData.saasMerNameEn }} Group Companies, including, without limitation, its owners, directors, investors, employees or other related parties. "Personal Information" refers to information that identifies an individual, such as name, address, e-mail address, trading information, and banking details. “Personal Information” does not include anonymised and/or aggregated data that does not identify a specific user.<br>
            By entering into the Agreement and the Terms and Conditions, you are specifically accepting the present {{ themeData.saasMerNameEn }} Privacy Policy and consenting to the collection, processing and communication of your data as described in this {{ themeData.saasMerNameEn }} Privacy Policy.<br>
            The purpose of this Privacy Policy is to describe:<br>
            1. The types of Personal Information we collect and how it may be used;<br>
            2. Our use of cookies and similar technology;<br>
            3. How and why we may disclose your Personal Information to third parties;<br>
            4. The transfer of your Personal Information within and outside of your home country;<br>
            5. Your right to access, correct, update, and delete your Personal Information;<br>
            6. The security measures we use to protect and prevent the loss, misuse, or alteration of Personal Information;and<br>
            7. {{ themeData.saasMerNameEn }}’s retention of your Personal Information.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">I. Introduction</p>
          <p>In order to be able to make {{ themeData.saasMerNameEn }}’s services available to you in compliance with the obligations imposed by Applicable Law and by regulatory authorities, {{ themeData.saasMerNameEn }} must ask you for certain information about yourself, including financial information (e.g., information about your identity, e-commerce sales, beneficiary account, etc.). {{ themeData.saasMerNameEn }} will utilize the data provided by you in accordance with applicable provisions of governing data protection laws. The information obtained shall, as a matter of principle, be used by {{ themeData.saasMerNameEn }} solely for providing {{ themeData.saasMerNameEn }} Services and solely to the extent necessary to render such services. The information about you which we store shall, as a matter of principle, not be made accessible to third parties unless we are obligated to do so by law or due to legal ordinances or in order to properly render our services to you.</p>
        </div>
        <div>
          <p class="navigationTitle">
            II. How we collect information
          </p>
          <p>
            A. Collected from Website Visitors<br>
            Like most website operators, {{ themeData.saasMerNameEn }} automatically collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. {{ themeData.saasMerNameEn }}’s purpose in collecting non-personally identifying information is to better understand how {{ themeData.saasMerNameEn }}’s visitors use its website, through cookies, web beacons, log files and other technologies:<br>
            (1) Your domain name, your browser type and operating system, web pages you view, links you click;<br>
            (2) Your IP address, the length of time you visit our Website or use our Services, your activities on our Website, and the referring URL or the webpage that led you to our Website.<br>
            Please see Section 8 “Cookies” below for more information. Visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from being able to use {{ themeData.saasMerNameEn }}’s services.<br>
            B. Provided by Users<br>
            You may provide us data about you by filling in forms on our website (e.g. {{ themeData.saasMerNameEn }} Account Registration), or by corresponding with us (for example, by e-mail or telephone). The data you provide may include data about additional persons that are beneficial owners of the {{ themeData.saasMerNameEn }} Services. This includes that is provided to us about you:<br>
            (1) upon registration for a {{ themeData.saasMerNameEn }} Account;<br>
            (2) when you log in to your {{ themeData.saasMerNameEn }} Account;<br>
            (3) when you submit any Settlement Orders through your {{ themeData.saasMerNameEn }} Account;<br>
            (4) when entering a competition, promotion or survey;<br>
            (5) when a problem is reported or a request for support is received.<br>
            Visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from being able to use {{ themeData.saasMerNameEn }}’s services.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">III. Information we collect</p>
          <p>We collect the Personal Information you provide directly to us when you apply for a {{ themeData.saasMerNameEn }} Account, perform any transactions on the {{ themeData.saasMerNameEn }} platform, or use other {{ themeData.saasMerNameEn }} Services. This may include:<br>
            1. Your contact information (e.g., name, email address, phone number, billing or mailing address)<br>
            2. Bank and credit account information<br>
            3. IP address<br>
            4. Identity validation (e.g., photograph, other information requested to verify your information, including copy of valid ID document)<br>
            5. Publicly available and/or criminal history<br>
            6. National identification numbers<br>
            7. Nationality<br>
            8. Date and place of birth<br>
            9. Details of any transactions carried out using any the services<br>
            10. Any other information that you choose to provide to us (e.g., if you send us an email/otherwise contact us)<br>
            11. Calls/emails/other correspondence<br>
            12. Information through Cookies and other tracking technologies as listed above and as described in the section below entitled “Cookies”<br>
            You are responsible for providing accurate and up-to-date information.<br>
            If you are required to provide information about shareholders or beneficial owners of your business, you acknowledge that you have that person’s consent to provide his/her information to us. This may include:<br>
            1. Contact information, such as name, home address, and email address.<br>
            2. Account information, such as username and credential.<br>
            3. Financial information, such as bank account numbers, bank statement, and trading information.<br>
            4. Identity verification information, such as images of your government issued ID, passport, national ID card, or driving license. Note: US residents may be asked to provide their social security numbers.<br>
            5. Residence verification information, such as Utility bill details or similar information.<br>
            We may collect and process Personal Information about Buyers or third parties related to your business in providing {{ themeData.saasMerNameEn }} Services. You are responsible for making sure that the privacy rights of any third parties, including Buyers and other individuals related to your business, are respected, including ensuring appropriate disclosures about third party data collection and use; with respect to such data you hereby (i) are deemed to be and (ii) accept to be controller. To the extent that we are acting as your data processor, we will process Personal Data in accordance with the terms of our agreement with you and your lawful instructions.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">IV. How we use your data</p>
          <p>We will only use your Personal Information to:<br>
            1. Process your transactions, including transfers into the Peoples Republic of China in accordance with State Administration of Foreign Exchange regulations.<br>
            2. Perform the required controls and checks in accordance with money laundering/terrorist financing and know your customer requirements under Applicable Law, and internal control policies, and well as address other law enforcement needs as more fully described in our Terms and Conditions.<br>
            3. Analyse {{ themeData.saasMerNameEn }} website usage, and improve our website and website offerings.<br>
            4. Help us respond to your customer service requests and support needs.<br>
            5. Contact you about {{ themeData.saasMerNameEn }} Services. The email address you provide may be used to communicate information and updates related to your use of the {{ themeData.saasMerNameEn }} Services. We may also occasionally communicate company news, updates, promotions, and related information relating to similar products and services provided by {{ themeData.saasMerNameEn }}.<br>
            6. Administer a contest, promotion, survey or other site features as will be more explained on the website.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">V. Disclosure of personal information</p>
          <p>{{ themeData.saasMerNameEn }} discloses potentially personal information only (a) to legal and regulatory authorities as required by Applicable Law, and (b) to those of its employees, service providers and affiliated organizations that (i) need to know that information in order to process it or to provide services, and (ii) that have agreed not to disclose it to others, as described below.<br>
            A. Disclosure to Third Parties<br>
            In processing your transactions, we may share some of your Personal Information with Service Providers who help with our business operations. Your information will not be sold, exchanged, or shared with any third parties without your consent, except to provide {{ themeData.saasMerNameEn }} Services or as required by law. By using our Services and accepting our Terms and Conditions, you consent to the disclosure of your Personal Information as described in this Privacy Policy.<br>
            {{ themeData.saasMerNameEn }}’s Service Providers are contractually bound to protect and use such information only for the purposes for which it was disclosed, except as otherwise required or permitted by law. We ensure that such third parties will be bound by terms no less protective those described in this Privacy Policy, or those we are subject to under applicable data protection laws, including but not limited to the Applicable Law.<br>
            B. Disclosure to Legal Authorities<br>
            We may share your Personal Information with law enforcement, data protection authorities, government officials, and other authorities when:<br>
            (1) Compelled by subpoena, court order, or other legal procedure.<br>
            (2) We believe that the disclosure is necessary to prevent physical harm or financial loss.<br>
            (3) Disclosure is necessary to report suspected illegal activity.<br>
            (4) Disclosure is necessary to investigate violations of this Privacy Policy or our Terms of Use.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">VI. Transfer and storage of data</p>
          <p>Our services are global and data may be stored and processed in any country where we have operations or where we engage service providers. Data we collect may be transferred to and/or stored at a destination outside your country of residence or the Account Jurisdiction, which may have data protection rules that are different from those of your country, including transferring data to and from regulatory authorities, or to staff operating outside the country who process data on our behalf or for one of our suppliers. Staff may be engaged in the fulfillment of your request and the provision of support services. However, we will take measures to ensure that any such transfers comply with applicable data protection laws and that your data remains protected to the standards described in this privacy policy. By submitting the data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that the data is treated securely and in accordance with this Privacy Policy and the relevant data protection regulations.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">VII. Protection of Certain Personally-Identifying Information</p>
          <p>{{ themeData.saasMerNameEn }} takes all organizational and technical measures appropriate to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.<br>
            All data that you provide to us is stored on our secure servers. You are responsible for keeping your account credentials safe and secure and not sharing them with anyone.<br>
            The transmission of information via the internet is not completely secure; any transmission is at your own risk. Although no one can guarantee the security of data transmitted via the internet, we do our best to protect the data transmitted via the {{ themeData.saasMerNameEn }} Account. We use industry standard security techniques to help keep the data safe including encryption when the data is in transit and at rest.<br>
            The {{ themeData.saasMerNameEn }} website may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any Personal  Information to these third parties.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">VIII. Cookies</p>
          <p>To make this site work properly, {{ themeData.saasMerNameEn }}, similar to many other major websites, sometimes use small data files called cookies or other tracking technology to track information about your use of our website and services. We may use third party service providers to collect this information on our behalf.<br>
            A. What are cookies?<br>
            A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.<br>
            B. How we use cookies and tracking mechanisms<br>
            {{ themeData.saasMerNameEn }}’s website uses the following cookies/tracking mechanisms.<br>
            • Session cookies. Session cookies are temporary cookies that remain in the cookie file of your browser until you leave the site. We use session cookies to allow you to carry information across pages of our site and avoid having to re-submit the same information. The cookies will be deleted after your web browser has been closed.<br>
            • Persistent cookies. Persistent cookies remain in the cookie file of your browser for much longer (though how long will depend on the lifetime of the specific cookie). We use persistent cookies:<br>
            • To help us recognize you as a unique visitor when you return to our website and to monitor your use of our website;<br>
            • To allow us to link you to any of our Partners of Affiliates should you come to our website through a paid advert or banner on a website of an Affiliate or Partner.<br>
            The cookies will be deleted based on their own expiration period after your web browser has been closed.<br>
            • Web beacons. Some of our web pages may contain web beacons which allow us to count users who have visited these pages. Web beacons collect only limited information including a cookie number, time and date of a page view, and a description of the page on which the web beacon resides. These beacons do not carry any personally identifiable information and are used to track the effectiveness of a particular marketing campaign.<br>
            C. How to control cookies<br>
            You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">IX. Privacy Policy Changes</p>
          <p>This Privacy Policy may be revised over time as new features are added to the {{ themeData.saasMerNameEn }} services or as we incorporate suggestions from our stakeholders. We may change this Privacy Policy at any time by posting a revised version of it on our website. We will provide you with at least 30 days' prior notice of the effective date of the revised Privacy Policy when it is legally required. We may post the notice on the front page of our website(s) and/or send the notice by e-mail, but we are under no obligation to do so. As of the effective date of the revised Privacy Policy, you will be considered as having consented to all changes to the Privacy Policy. If you disagree with the terms of this Privacy Policy, you may close your account at any time.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">X. Liability</p>
          <p>We are not liable to you in respect of any claims, losses, damages, expenses (including reasonable legal fees) arising out of or in connection with the use and/or disclosure or dissemination of the user data in accordance with this privacy policy and any consents that you may have otherwise provided to us.<br>
          </p>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue'
export default {
  name: 'PrivacyPolicyEN',
  setup () {
    const state = reactive({
      themeData: inject('$themeData')
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
p.navigationTitle{margin-top: 20px;font-size: 18px;color: black;font-weight: bold;}
</style>
